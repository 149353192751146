import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Helmet } from "react-helmet";
import { Keywords } from "../../components/constants";
import Layout from "../../components/layout";

const PortfolioIndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Portfolio | JBLTX</title>
        <meta name="author" content="Mickael Bonfill" />
        <meta name="description" content="Discover every project made by JBLTX" />
        <meta name="keywords" content={Keywords.join(", ")} />
</Helmet>

      <h1 className="page-title">Portfolio</h1>

      <div className="portfolio-list">
        <a className="portfolio-entry" target={"_blank"} rel="noreferrer noopener" href="https://opensea.io/collection/wild-mindz">
          <StaticImage alt="Wild Mindz Banner" className="portfolio-entry-image" src="https://s3.amazonaws.com/nft.jbltx.com/wild-mindz/artworks/0.png" />
          <h2 className="portfolio-entry-title">Wild Minz</h2>
        </a>

        <a className="portfolio-entry" target={"_blank"} rel="noreferrer noopener" href="https://opensea.io/collection/neon-exploration">
          <StaticImage alt="Neon Exploration Banner" className="portfolio-entry-image" src="https://lh3.googleusercontent.com/TtAnsRLPOVOgDoZ-qgXSzh0dC3krrFUwB4d1VlR4GfGuuKITH0BIQwqOirE2DkbacBuMcVeLDu79YCNsLCJcQHZPOgunLsB3MMbFCx0=s0" />
          <h2 className="portfolio-entry-title">Neon Exploration</h2>
        </a>
      </div>
    </Layout>
  );
};

export default PortfolioIndexPage;
